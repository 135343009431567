
<template>
  <div class="customerContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>

      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding: 0px 0 40px">
          典型客户
        </h4>
        <button @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="schoolList">
        <h4 class="descH4">400<span class="jiaTop" style="font-size:28px;margin-right:5px">+</span>高校的最优选择</h4>
        <span class="line"></span>
        <div class="schoolContent">
          <div class="schoolBtn">
            双一流院校 70<span class="jiaTop" style="font-size:20px">+</span>，市场份额 50%<span class="jiaTop" style="font-size:20px">+</span>
            <img class="schoolWave" src="@/assets/home/wave.png" alt="">
          </div>
          <img src="@/assets/TypicalCase/Customer/syl.png" alt="" style="width:1200px">
          <span style="font-size: 14px;color:#999;padding:20px 0 40px;">排名不分先后</span>
        </div>
      </div>

      <div class="schoolListCon" v-for="(item,index) in shcoolImg" :key="index">
        <div class="schoolBtn">
          {{item.title}}<span class="jiaTop" style="font-size:20px">+</span>{{item.titleTwo}}
          <img class="schoolWave" src="@/assets/home/wave.png" alt="">
        </div>
        <img :src="item.img" alt="" style="width:1200px">
        <span style="font-size: 14px;color:#999;padding:20px 0 40px;">排名不分先后</span>
      </div>

      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'Customer',
  data () {
    return {
      schoolShow: 0,
      schoolContentOne: [
        { title: '清华大学', src: require('@/assets/TypicalCase/Customer/qinghua.png') },
        { title: '北京航空航天大学', src: require('@/assets/TypicalCase/Customer/beijinhangkong.png') },
        { title: '北京师范大学', src: require('@/assets/TypicalCase/Customer/beijingshifan.png') },
        { title: '南开大学', src: require('@/assets/TypicalCase/Customer/nankai.png') },
        { title: '吉林大学', src: require('@/assets/TypicalCase/Customer/jilin.png') },
        { title: '华东师范大学', src: require('@/assets/TypicalCase/Customer/huadongshifan.png') },
        { title: '东南大学', src: require('@/assets/TypicalCase/Customer/dongnan.png') },
        { title: '山东大学', src: require('@/assets/TypicalCase/Customer/sandong.png') },
        { title: '武汉大学', src: require('@/assets/TypicalCase/Customer/wuhan.png') },
        { title: '华中科技大学', src: require('@/assets/TypicalCase/Customer/huazhongkeji.png') },
        { title: '中南大学', src: require('@/assets/TypicalCase/Customer/zhongnan.png') },
        { title: '中山大学', src: require('@/assets/TypicalCase/Customer/zhongsan.png') },
        { title: '重庆大学', src: require('@/assets/TypicalCase/Customer/chongqing.png') },
        { title: '西安交通大学', src: require('@/assets/TypicalCase/Customer/xianjiaotong.png') },
        { title: '东北大学', src: require('@/assets/TypicalCase/Customer/dongbei.png') },
        { title: '西北农林科技大学', src: require('@/assets/TypicalCase/Customer/xibeininglin.png') },
        { title: '新疆大学', src: require('@/assets/TypicalCase/Customer/xingjiang.png') },
        { title: '中央财经大学', src: require('@/assets/TypicalCase/Customer/zhongyangcaijing.png') },
        { title: '中国政法大学', src: require('@/assets/TypicalCase/Customer/zhongguozhengfa.png') },
        { title: '太原理工大学', src: require('@/assets/TypicalCase/Customer/taiyuanligong.png') },
        { title: '东北师范大学', src: require('@/assets/TypicalCase/Customer/dongbeishifan.png') },
        { title: '东北林业大学', src: require('@/assets/TypicalCase/Customer/dongbeilinye.png') },
        { title: '上海外国语大学', src: require('@/assets/TypicalCase/Customer/shanghaiwaiguoyu.png') },
        { title: '南京航空航天大学', src: require('@/assets/TypicalCase/Customer/nanjinghangkong.png') },
        { title: '合肥工业大学', src: require('@/assets/TypicalCase/Customer/hefeigongye.png') }
      ],
      schoolContentTwo: [
        { title: '首都经济贸易大学', src: require('@/assets/TypicalCase/Customer/shoudujingjimaoyi.png') },
        { title: '长春大学', src: require('@/assets/TypicalCase/Customer/changchun.png') },
        { title: '中国医科大学', src: require('@/assets/TypicalCase/Customer/zhongguoyike.png') },
        { title: '内蒙古师范大学', src: require('@/assets/TypicalCase/Customer/neimenggushifan.png') },
        { title: '天津师范大学', src: require('@/assets/TypicalCase/Customer/tianjingshifan.png') },
        { title: '新疆医科大学', src: require('@/assets/TypicalCase/Customer/xingjaingyike.png') },
        { title: '青岛科技大学', src: require('@/assets/TypicalCase/Customer/qingdaokeji.png') },
        { title: '西安工业大学', src: require('@/assets/TypicalCase/Customer/xiangongye.png') },
        { title: '上海理工大学', src: require('@/assets/TypicalCase/Customer/shanghailigong.png') },
        { title: '上海师范大学', src: require('@/assets/TypicalCase/Customer/shanghaishifan.png') },
        { title: '浙江理工大学', src: require('@/assets/TypicalCase/Customer/zhejiangligong.png') },
        { title: '宁波财经学院', src: require('@/assets/TypicalCase/Customer/ningbocaijing.png') },
        { title: '湖北大学', src: require('@/assets/TypicalCase/Customer/hubei.png') },
        { title: '武汉科技大学', src: require('@/assets/TypicalCase/Customer/wuhankeji.png') },
        { title: '湘潭大学', src: require('@/assets/TypicalCase/Customer/xiangtan.png') },
        { title: '四川外国语大学', src: require('@/assets/TypicalCase/Customer/sichuanwaiguoyu.png') },
        { title: '昆明理工大学', src: require('@/assets/TypicalCase/Customer/kunmingligong.png') },
        { title: '云南中医药大学', src: require('@/assets/TypicalCase/Customer/yunnanzhongyao.png') },
        { title: '深圳技术大学', src: require('@/assets/TypicalCase/Customer/shenzheng.png') },
        { title: '广州大学', src: require('@/assets/TypicalCase/Customer/guangzhou.png') },
        { title: '华南农业大学', src: require('@/assets/TypicalCase/Customer/nanhuanongye.png') }

      ],
      schoolContentThree: [
        { title: '无锡职业技术学院', src: require('@/assets/TypicalCase/Customer/wuxuzhiye.png') },
        { title: '天津市职业大学', src: require('@/assets/TypicalCase/Customer/tianjingshi.png') },
        { title: '深圳职业技术学院', src: require('@/assets/TypicalCase/Customer/shengzhengzhiye.png') },
        { title: '杭州职业技术学院', src: require('@/assets/TypicalCase/Customer/hangzhouzhiye.png') },
        { title: '四川工程职业技术学院', src: require('@/assets/TypicalCase/Customer/shichuangongchengzhiye.png') },
        { title: '武汉职业技术学院', src: require('@/assets/TypicalCase/Customer/wuhanzhiye.png') },
        { title: '河南工业职业技术学院', src: require('@/assets/TypicalCase/Customer/henangongyezhiye.png') },
        { title: '柳州职业技术学院', src: require('@/assets/TypicalCase/Customer/liuzhouzhiye.png') },
        { title: '广东机电职业技术学院', src: require('@/assets/TypicalCase/Customer/guangdongjidian.png') },
        { title: '重庆三峡职业学院', src: require('@/assets/TypicalCase/Customer/chongqingshanxiazhiye.png') },
        { title: '贵州装备制造职业学院', src: require('@/assets/TypicalCase/Customer/guizhouzhuangbei.png') },
        { title: '广西交通职业技术学院', src: require('@/assets/TypicalCase/Customer/guangxijiaotong.png') }
      ],
      shcoolImg: [
        { img: require('@/assets/TypicalCase/Customer/bk.png'), title: '本科院校 200', titleTwo: '，占比第一' },
        { img: require('@/assets/TypicalCase/Customer/zyyx.png'), title: '职业院校100', titleTwo: '，占比领先' },
        { img: require('@/assets/TypicalCase/Customer/kkys.png'), title: '科研院所、医院50', titleTwo: '' }
      ]
    }
  },
  methods: {
    schoolHandle (v) {
      const lis = Array.from(this.$refs.schoolUl.children)
      lis.forEach(item => {
        item.children[0].style.bottom = '-20px'
        item.children[0].style.left = '-173px'
      })
      lis[v].children[0].style.bottom = '-1px'
      lis[v].children[0].style.left = '-1px'
      this.schoolShow = v
    },
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moCooperation/moCustomer')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped>
.customerContainer{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}
.channelShip {
  padding-top: 80px;
}
.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/Cooperation/Partnership/banner.png") center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}

.schoolList{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.schoolContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.schoolBtn{
  position: relative;
  top: 0;
  left: 0;
  width: 600px;
  height: 78px;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  box-shadow: 0px 8px 32px -6px rgba(131,7,7,0.4);
  border-radius: 10px;
  line-height: 78px;
  text-align: center;
  font-size: 24px;
  color: #fff;
  margin: 60px 0 28px;
}
.schoolWave{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 600px;
}
.schoolListCon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.jiaTop{
  position: relative;
  top: -10px;
  left: 0;
}
</style>
